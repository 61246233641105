import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import blogPostImg from '../images/blogPostImg.jpg'
import blogPostImg2 from '../images/blogPostImg2.jpg'
import blogPostImg3 from '../images/blogPostImg3.jpg'
import blogPostImg4 from '../images/blogPostImg4.jpg'
import blogPostImg5 from '../images/blogPostImg5.jpg'
import blogPostImg6 from '../images/pandemicera.jpg'
import { motion } from "framer-motion"
import 'aos/dist/aos.css'


const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 14px;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  margin-right: 4em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 1em 0;
`

const CTAWrapper = styled.div``

export const BlogPosts: React.FunctionComponent = () => {
let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  const [isHovered, setHovered] = useState(false)
  return (
    <CTAWrapper
      style={{ maxWidth: '90%', margin: 'auto' }}
      className="socialWrapp3r blogWrapper"
    >
      <GalleryWrapper className="blogGalleryWrapper">
         <div
          style={{
            display: 'inline-flex',
            placeContent: 'center',
            paddingBottom: '1em',
            paddingTop: '3em',
          }}
          className="socialContain3r secondContainerRow"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
          <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}
            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
            
          >
            <Link
              to="/pandemicera"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg6}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          The Pandemic-Era Trends Impacting Real Estate Profitability
                           
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          December 28, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          Whether you're bullish or bearish on the financial impacts of Covid-19, this is undoubtedly a time to profit on real estate if you know how to navigate the market.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
          
          <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}
            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
            
          >
            <Link
              to="/propmanagement"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          How Property Management Can Make It Through A Pandemic
                           
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          July 13, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          In this time of great hardship, let us be mindful that we are indeed in this together, and commit to treating one another with the respect that all humankind deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
          <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}

            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
          >
            <Link
              to="/truecolours"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg2}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          It Is Time For Our True Colors To Illustrate True Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          May 28, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          Let your true colors show in the small things and the bigger picture will always stay focused.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
          </div>
      
        <div
          style={{
            display: 'inline-flex',
            placeContent: 'center',
            paddingBottom: '1em',
            paddingTop: '3em',
            justifyContent: 'left',
          }}
          className="socialContain3r secondContainerRow"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
           <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}

            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
          >
            <Link
              to="/achievesmartgoals"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg3}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          Achieve More By Setting SMART Goals
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          Jan 27, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          As a business owner, evaluate these key facets of your
                          life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/01/27/achieve-more-by-setting-smart-goals/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
         
          <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}

            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
          >
            <Link
              to="/fourwaysprofit"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg4}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/fourwaysprofit"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          Four Ways Location Affects Profits
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/fourwaysprofit"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/fourwaysprofit"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          Practically everyone in business has heard that one of
                          the most critical factors to ensure a profitable
                          business is location, location, location.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2019/12/13/four-ways-location-affects-profits/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
          <motion.FlexDiv
            style={{
              flex: '1',
              flexFlow: 'column',
              width: '33%',
              maxWidth: '33%'
            }}
            whileHover={{ scale: 1.025 }}
            onHoverStart={e => {}}
            onHoverEnd={e => { }}
            // whileTap={{ scale: 0.95 }}
            className="blogBox"
          >
            <Link
              to="/realestatebrand"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
            >
              <img
                src={blogPostImg5}
                style={{ width: '90%' }}
                className="socialImg"
              />
            </Link>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv>
                      <BlogTitle>
                        <Link
                          to="/realestatebrand"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'Montserrat-SemiBold',
                          }}
                          className="blogTitle"
                        >
                          {' '}
                          Breaking Through With Your Real Estate Brand
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/realestatebrand"
                          target="_blank"
                          style={{ textDecoration: 'none', color: 'gray', fontFamily: 'Montserrat', fontSize: 11
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }} className="blogExcerpt">
                        <Link
                          to="/realestatebrand"
                          target="_blank"
                          style={{ textDecoration: 'none', color: '#212121', fontFamily: 'Montserrat', fontSize: 11
                          }}
                          
                        >
                          Branding a company, large or small, takes into account
                          everything from the target market to the tagline.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em', marginTop: '10px' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2019/10/22/breaking-through-with-your-real-estate-brand/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </motion.FlexDiv>
        </div>
      </GalleryWrapper>
    </CTAWrapper>
  )
}
