import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import hero from '../images/blogPageHero.jpg'
import "aos/dist/aos.css"

const OurPromiseWrapper = styled.div`
  margin: auto;
  padding: 10em;
  display: flex;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(6, 6, 6, 0.23)
    ),
    url(https://northwhick.netlify.app/ellicot-assets/AboutHero.jpg);
      background-size: cover;
    background-position: center;
`

const CTATitle = styled.h2`
  font-family: 'Montserrat-Bold';
  color: #fff;
  letter-spacing: 1px;
  font-size: 3.75rem;
  text-align: center;
`

const CTAWrapper = styled.div``

export const Hero: React.FunctionComponent = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <CTAWrapper
               data-aos="fade-up"
               data-aos-delay="50"
               data-aos-duration="2000">
      <OurPromiseWrapper className="aboutHero" style={{ backgroundImage: 'url(' + hero + ')',}}>
        <FlexDiv style={{ flexFlow: 'column', padding: '0.5rem' }}>
          <CTATitle> JOURNAL</CTATitle>
        </FlexDiv>
      </OurPromiseWrapper>
    </CTAWrapper>
  )
}
