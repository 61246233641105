import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { BlogPosts } from 'components/BlogPosts'
import { PrefClientPortal } from 'components/PrefClientPortal'
import { Hero } from 'components/BlogHero'
import { Contact } from 'components/Contact'

const JournalPage: React.FunctionComponent = () => {
  return (
    
    <EllicotLayout>
      <div className="contactPage">
        <Hero
         />
        <BlogPosts />
        {/* <PrefClientPortal /> */}
      </div>
      <Contact />
    </EllicotLayout>
  )
}

export default JournalPage
